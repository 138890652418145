import { ColumnDef } from "@tanstack/react-table";
import { Patient } from "../../../types";
import { Mail, MessageCircleMore, Phone } from "lucide-react";
import { useTranslation } from "react-i18next";

export const PatientContactInfoColName = "ContactInfo";

export const PatientContactInfoCol: ColumnDef<Patient> = {
  id: PatientContactInfoColName,
  accessorKey: "name",
  size: 150,
  minSize: 100,
  header: () => {
    const { t } = useTranslation();

    return <p>{t("case-load-manager-column-contact-information")}</p>;
  },
  cell: ({ row }) => {
    const email = row.original.email;
    const wireUserName = row.original.wireUserName;
    const phone = row.original.phoneNumber;

    return (
      <div className="text-xs">
        <div className="flex items-center space-x-2">
          <Mail className="w-4 h-4" />
          <p>{email}</p>
        </div>

        {phone && (
          <div className="flex items-center space-x-2 pt-1">
            <Phone className="w-4 h-4" />
            <p>{phone}</p>
          </div>
        )}

        {wireUserName && (
          <div className="flex items-center space-x-2 pt-1">
            <MessageCircleMore className="w-4 h-4" />
            <p>{wireUserName}</p>
          </div>
        )}
      </div>
    );
  },
};
