import { ColumnDef } from "@tanstack/react-table";
import { Patient } from "../../../types";
import { useTranslation } from "react-i18next";

export const NumberOfRegularConsultsColName = "Number of Regular Consults";

export const NumberOfRegularConsultsCol: ColumnDef<Patient> = {
  id: NumberOfRegularConsultsColName,
  accessorKey: "numberOfRegularConsults",
  size: 100,
  minSize: 80,
  header: () => {
    const { t } = useTranslation();
    return <p>{t("case-load-manager-column-number-of-regular-consults")}</p>;
  },
  cell: ({ getValue }) => {
    return <p>{getValue() as string}</p>;
  },
};
