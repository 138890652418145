import { ColumnDef } from "@tanstack/react-table";
import { Patient } from "../../../types";
import { Button } from "../../../../../components/ui/button";
import { useState } from "react";
import { CreateOffboarding } from "../../../offboardingPatients/createOffboarding";
import { UndoMarkAsOffboarding } from "../../../offboardingPatients/undoMarkAsOffboarding";
import { Ban, CircleCheckBig } from "lucide-react";
import { useTranslation } from "react-i18next";
import { UpdateOffboardingPatient } from "../../../offboardingPatients/updateDialog";

export const OffboardingColName = "Offboarding";

export const OffboardingCol: ColumnDef<Patient> = {
  id: OffboardingColName,
  accessorKey: "isSoonToBeDischarged",
  size: 270,
  minSize: 270,
  header: () => {
    return <p>Offboarding</p>;
  },
  cell: ({ getValue, row: { original } }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const isSoonOffboarding = getValue() as boolean;
    const numberOfRegularConsults = original.numberOfRegularConsults;
    const isMarkedAsOffboarding = original.offboardingData !== null;
    const hasAnythingBeenProcessed =
      original.offboardingData?.hasSentLastWireMessageToPatient ||
      original.offboardingData?.hasSentTreatmentSummaryToPraktijkData ||
      original.offboardingData?.hasSentWireConversationExportToPraktijkdata;

    if (hasAnythingBeenProcessed) {
      return (
        <div>
          <p className="font-bold">
            {t("case-load-manager-offboarding-main-list-col-feedback-title")}
          </p>
          <div className="">
            <div className="space-y-1 mt-2">
              <OffboardingStepStatus
                label={t(
                  "case-load-manager-offboarding-main-list-col-feedback-treatment-plan-to-pd"
                )}
                isSuccess={
                  original.offboardingData!
                    .hasSentTreatmentSummaryToPraktijkData
                }
              />
              <OffboardingStepStatus
                label={t(
                  "case-load-manager-offboarding-main-list-col-feedback-wire-message"
                )}
                isSuccess={
                  original.offboardingData!.hasSentLastWireMessageToPatient
                }
              />
              <OffboardingStepStatus
                label={t(
                  "case-load-manager-offboarding-main-list-col-feedback-wire-export"
                )}
                isSuccess={
                  original.offboardingData!
                    .hasSentWireConversationExportToPraktijkdata
                }
              />
            </div>
          </div>
        </div>
      );
    }

    if (isMarkedAsOffboarding) {
      return (
        <div>
          <div className="p-2 rounded-lg bg-green-100 text-green-600 inline-block">
            <p>{t("case-load-manager-offboarding-next-consult-is-last")}</p>
          </div>
          <UpdateOffboardingPatient
            id={original.offboardingData!.id}
            originalTreatmentSummary={
              original.offboardingData!.treatmentSummary
            }
            originalSendRequestToGoogleReviewToPatient={
              original.offboardingData!.sendRequestToGoogleReviewToPatient
            }
          />
          <UndoMarkAsOffboarding id={original.offboardingData!.id} />
        </div>
      );
    }

    if (!isSoonOffboarding) {
      return <></>;
    }

    return (
      <div>
        <p>
          {t("case-load-manager-offboarding-main-button-caption", {
            count: numberOfRegularConsults,
          })}
        </p>
        <Button onClick={() => setIsOpen(true)} className="mt-1">
          {t("case-load-manager-offboarding-main-button-label")}
        </Button>

        <CreateOffboarding
          currentWeekCaseLoadEntryId={original.id}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
    );
  },
};

const OffboardingStepStatus = ({
  label,
  isSuccess,
}: {
  label: string;
  isSuccess: boolean;
}) => {
  if (isSuccess) {
    return (
      <div className="flex items-center space-x-2 text-xs">
        <div className="w-3 h-3">
          <CircleCheckBig className="w-3 h-3" />
        </div>
        <p>{label}</p>
      </div>
    );
  } else {
    return (
      <div className="flex items-center space-x-2 text-xs text-red-600">
        <div className="w-3 h-3">
          <Ban className="w-3 h-3" />
        </div>
        <p>{label}</p>
      </div>
    );
  }
};
