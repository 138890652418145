import { ColumnDef } from "@tanstack/react-table";
import { Patient } from "../../../types";
import { SortableCell } from "../../../../../components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../../../../components/ui/hover-card";
import { ExternalLink, Trash2, Info } from "lucide-react";
import { useState } from "react";
import { isPeriodInPastWeeks } from "../../../utils";
import { PerPatientAutomaticBillingDialog } from "../../../automaticBillings/perPatientDialog";
import { RemovePatientDialog } from "../../removePatient/removePatientDialog";
import { useTranslation } from "react-i18next";

export const PatientColName = "Patient";

export const PatientCol: ColumnDef<Patient> = {
  id: PatientColName,
  accessorKey: "name",
  size: 175,
  minSize: 100,
  header: ({ column }) => {
    const { t } = useTranslation();

    return (
      <SortableCell
        column={column}
        label={t("case-load-manager-column-patient")}
      />
    );
  },
  cell: ({ row, table }) => {
    const { t } = useTranslation();

    const dossier = `${row.original.epdPatientId}.${row.original.epdFileId}`;
    const userName = row.original.name;

    const [
      isRemovePatientConfirmationOpen,
      setIsRemovePatientConfirmationOpen,
    ] = useState(false);

    const [
      isViewBillingOfPatientDialogOpen,
      setIsViewBillingOfPatientDialogOpen,
    ] = useState(false);

    const isInPast = isPeriodInPastWeeks(row.original.period);

    return (
      <div className="min-h-20 flex flex-col justify-center">
        <p className="text-xs">{dossier}</p>
        <div>
          <HoverCard openDelay={100}>
            <HoverCardTrigger asChild>
              <p
                className="font-bold cursor-pointer underline hover:text-ipractice-blue"
                onClick={() =>
                  window.open(
                    `https://start.praktijkdata.nl/app/clienten/${row.original.epdPatientId}`,
                    "_blank"
                  )
                }
              >
                {userName}
              </p>
            </HoverCardTrigger>
            <HoverCardContent
              align="start"
              side="bottom"
              className="text-gray-700 p-2"
            >
              <div
                className="cursor-pointer hover:underline flex gap-2 py-1 items-center"
                onClick={() => {
                  window.open(
                    `/patient/${row.original.patientId}/DossierGgz/${row.original.dossierId}/treatment`,
                    "_blank"
                  );
                }}
              >
                <ExternalLink className="w-4 h-4" />
                <p>
                  {t("case-load-manager-row-actions-view-patient-in")}{" "}
                  <span className="font-semibold">Dashboard</span>
                </p>
              </div>
              <div
                className="cursor-pointer hover:underline flex gap-2 py-1 items-center"
                onClick={() =>
                  window.open(
                    `https://start.praktijkdata.nl/app/clienten/${row.original.epdPatientId}`,
                    "_blank"
                  )
                }
              >
                <ExternalLink className="w-4 h-4" />
                <p>
                  {t("case-load-manager-row-actions-view-patient-in")}{" "}
                  <span className="font-semibold">Praktijkdata</span>
                </p>
              </div>
              <hr className="w-full h-1" />

              <div
                className={`py-1 ${
                  isInPast || row.original.hasChattedWith
                    ? "opacity-50"
                    : "cursor-pointer hover:underline"
                }`}
                onClick={() => {
                  if (row.original.hasChattedWith || isInPast) return;

                  setIsRemovePatientConfirmationOpen(true);
                }}
              >
                <div
                  className={`${
                    isInPast || row.original.hasChattedWith
                      ? "text-red-400"
                      : "text-red-600"
                  } flex items-center justify-between w-full`}
                >
                  <div className="flex items-center gap-2">
                    <Trash2 className="h-4 w-4" />
                    <p>{t("case-load-manager-row-actions-remove-patient")}</p>
                  </div>

                  {row.original.hasChattedWith && (
                    <div className="h-4 w-4">
                      <TooltipProvider delayDuration={100}>
                        <Tooltip>
                          <TooltipTrigger>
                            <Info className="h-4 w-4" />
                          </TooltipTrigger>
                          <TooltipContent className="w-72">
                            {t(
                              "case-load-manager-cannot-remove-single-patient-desc-tooltip"
                            )}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  )}
                </div>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>

        <RemovePatientDialog
          isOpen={isRemovePatientConfirmationOpen}
          setIsOpen={setIsRemovePatientConfirmationOpen}
          caseLoadEntryId={row.original.id}
          patient={row.original}
          resetRowSelection={() => table.resetRowSelection()}
        />

        <PerPatientAutomaticBillingDialog
          isOpen={isViewBillingOfPatientDialogOpen}
          setIsOpen={setIsViewBillingOfPatientDialogOpen}
          patient={row.original}
        />
      </div>
    );
  },
};
