import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Patient } from "../../../types";
import { useTranslation } from "react-i18next";
import { isPeriodInPastWeeks } from "../../../utils";
import { Textarea } from "../../../../../components/ui/textarea";
import { Button } from "../../../../../components/ui/button";
import { Switch } from "../../../../../components/ui/switch";
import { Label } from "../../../../../components/ui/label";
import { Info, Loader2, Save, Undo } from "lucide-react";
import { useUpdateTreatment } from "../../hooks";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const TreatmentColName = "Treatment";

export const TreatmentCol: ColumnDef<Patient> = {
  id: TreatmentColName,
  accessorKey: "treatment",
  size: 225,
  minSize: 100,
  header: () => {
    const { t } = useTranslation();
    return <p>{t("case-load-manager-column-treatment")}</p>;
  },
  cell: ({ getValue, row }) => {
    const { t } = useTranslation();
    const defaultValue = getValue() as string | undefined;
    const [modifiedValue, setModifiedValue] = useState<string | undefined>(defaultValue);

    let hasChanged = (defaultValue ?? "") !== (modifiedValue ?? "");

    const isInPast = isPeriodInPastWeeks(row.original.period);

    const { mutate: save, isLoading: isSaving } = useUpdateTreatment();

    if (isInPast) return <p>{defaultValue?.toString() ?? ""}</p>;

    return (
      <div>
        <Textarea
          value={modifiedValue ?? ""}
          className="text-xs"
          onChange={(e) => setModifiedValue(e.target.value)}
          disabled={isSaving}
          rows={4}
        />
        {hasChanged && (
          <div className="flex gap-2">
            <Button
              className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
              disabled={!hasChanged}
              onClick={async () =>
                await save({
                  caseLoadId: row.original.id,
                  treatment: modifiedValue,
                })
              }
            >
              {isSaving ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>{t("case-load-manager-saving")}</p>
                </>
              ) : (
                <>
                  <Save className="w-4 h-4" />
                  <p>{t("case-load-manager-save")}</p>
                </>
              )}
            </Button>
            <Button
              variant="outline"
              className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
              disabled={!hasChanged}
              onClick={() => setModifiedValue(defaultValue)}
            >
              <Undo className="w-4 h-4" />
              <p>{t("case-load-manager-undo")}</p>
            </Button>
          </div>
        )}

        <ViewPraktijkDataTreatmentPlan
          value={row.original.epdTreatment.treatmentPlan}
          aiSummary={row.original.epdTreatment.aiTreatmentPlanSummary}
        />
      </div>
    );
  },
};

const ViewPraktijkDataTreatmentPlan = ({
  value,
  aiSummary,
}: {
  value: string | null;
  aiSummary: string | null;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMarkdown, setShowMarkdown] = useState(aiSummary !== null);

  return (
    <>
      <div
        className={`${
          value ? "visible" : "hidden"
        } pt-2 inline-flex items-start space-x-1 text-link cursor-pointer`}
        onClick={() => setIsOpen(true)}
      >
        <div className="h-4 w-4">
          <Info className="h-4 w-4" />
        </div>
        <p className="hover:underline -mt-[2px]">PraktijkData Treatment Plan</p>
      </div>

      <Dialog
        open={isOpen}
        onOpenChange={(newValue) => {
          setIsOpen(newValue);
        }}
      >
        <DialogContent className="p-8 min-w-[50vw] max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>PraktijkData Treatment Plan</DialogTitle>
            <DialogDescription>
              {showMarkdown ? (
                <ReactMarkdown
                  className="pt-4"
                  remarkPlugins={[
                    [
                      remarkGfm,
                      { tableCellPadding: true, tablePipeAlign: true, stringLength: true },
                    ],
                  ]}
                  components={{
                    table: ({ node, ...props }) => (
                      <table className="border-collapse border border-gray-300" {...props} />
                    ),
                    th: ({ node, ...props }) => (
                      <th className="border border-gray-300 p-2" {...props} />
                    ),
                    td: ({ node, ...props }) => (
                      <td className="border border-gray-300 p-2" {...props} />
                    ),
                  }}
                >
                  {aiSummary}
                </ReactMarkdown>
              ) : (
                <p className="whitespace-pre-wrap pt-4">{value}</p>
              )}
              <div className="flex items-center space-x-2 pt-4 pd-4">
                <Switch checked={showMarkdown} onCheckedChange={setShowMarkdown} />
                <Label>Show Ai Summary</Label>
              </div>
            </DialogDescription>
            <div className="flex items-center justify-center w-full pt-4">
              <Button variant="outline" onClick={() => setIsOpen(false)} className="px-12">
                Close
              </Button>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};