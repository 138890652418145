import { ColumnDef } from "@tanstack/react-table";
import { Patient } from "../../../types";
import { SortableCell } from "../../../../../components/ui/table";
import { getSimpleFormattedDateTime } from "../../../utils";
import { useTranslation } from "react-i18next";

export const LastRegularConsultOnColName = "Last Regular Consult On";

export const LastRegularConsultOnCol: ColumnDef<Patient> = {
  id: LastRegularConsultOnColName,
  accessorKey: "lastRegularConsultOn",
  size: 150,
  minSize: 150,
  header: ({ column }) => {
    const { t } = useTranslation();
    return (
      <SortableCell
        column={column}
        label={t("case-load-manager-column-last-regular-consult")}
      />
    );
  },
  cell: ({ getValue }) => {
    const dateAsString = getValue()?.toString();

    if (!dateAsString) {
      return <></>;
    }

    return <p>{getSimpleFormattedDateTime(dateAsString)}</p>;
  },
};
