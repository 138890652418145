import { Button } from "../../../../../../../components/ui/button";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../../../../../components/ui/dialog";

export const ConfirmUncheckVideoCalledWithDialog = ({
  open,
  onOpenChange,
  onConfirmed,
}: {
  open: boolean;
  onOpenChange: (newValue: boolean) => void;
  onConfirmed: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onOpenChange={(newValue) => {
        onOpenChange(newValue);
      }}
    >
      <DialogContent className="p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            {t(
              "case-load-manager-remove-videocalled-with-confirm-dialog-title"
            )}
          </DialogTitle>
          <DialogDescription>
            <p className="pt-2">
              {t(
                "case-load-manager-remove-videocalled-with-confirm-dialog-desc"
              )}
            </p>
          </DialogDescription>
          <DialogFooter className="flex justify-center">
            <Button className="px-6" onClick={() => onConfirmed()}>
              {t("case-load-manager-ok")}
            </Button>
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
