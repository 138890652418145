import React, { useState, createContext, useContext, ReactNode } from "react";

interface TabContextProps {
  activeTab: number;
  setActiveTab: (index: number) => void;
}

const TabContext = createContext<TabContextProps | undefined>(undefined);

interface HorizontalTabProps {
  children: ReactNode;
}

const HorizontalTab: React.FC<HorizontalTabProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab }}>
      <div className="flex items-center ml-2 w-full">{children}</div>
    </TabContext.Provider>
  );
};

interface HorizontalTabHeaderProps {
  children: ReactNode;
  index: number;
}

const HorizontalTabHeader: React.FC<HorizontalTabHeaderProps> = ({
  children,
  index,
}) => {
  const { activeTab, setActiveTab } = useContext(TabContext)!;
  const isSelected = activeTab === index;

  return (
    <div
      onClick={() => setActiveTab(index)}
      className={`relative py-2 px-10 cursor-pointer ${
        isSelected ? "border-b-4 border-ipractice-blue font-bold" : ""
      }`}
    >
      {children}
    </div>
  );
};

interface HorizontalTabContentProps {
  children: ReactNode;
  index: number;
}

const HorizontalTabContent: React.FC<HorizontalTabContentProps> = ({
  children,
  index,
}) => {
  const { activeTab } = useContext(TabContext)!;
  const isSelected = activeTab === index;

  return isSelected ? (
    <div className="bg-background rounded-lg border border-gray-200 dark:border-slate-800 p-10 -mt-[2px]">
      {children}
    </div>
  ) : null;
};

export { HorizontalTab, HorizontalTabHeader, HorizontalTabContent };
