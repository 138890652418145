import { ColumnDef } from "@tanstack/react-table";
import { AlertCode, CaseLoadAlert, Patient } from "../../../types";
import { AlertCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { Button } from "../../../../../components/ui/button";
import { useResolveAlerts } from "../../hooks";
import { toast } from "sonner";
import { InactiveDossierOfCaseLoadEntry } from "../../alerts/inactiveDossierOfCaseLoadEntry";

export const AlertsColName = "Alerts";

export const AlertsCol: ColumnDef<Patient> = {
  id: AlertsColName,
  accessorKey: "alerts",
  size: 100,
  minSize: 80,
  header: () => {
    const { t } = useTranslation();

    return (
      <p className="text-center">{t("case-load-manager-column-alerts")}</p>
    );
  },
  cell: ({ getValue, row: { original } }) => {
    const { t } = useTranslation();
    const alerts = getValue() as CaseLoadAlert[];

    const { mutate: resolveAlerts, isLoading: isResolvingAlerts } =
      useResolveAlerts(() => {
        toast(t("case-load-manager-resolved-alert-success-toast-title"), {
          description: t("case-load-manager-resolved-alert-success-toast-desc"),
        });
      });

    if (alerts.some((a) => a.code === "DossierNoLongerActive")) {
      return (
        <InactiveDossierOfCaseLoadEntry
          caseLoadId={original.id}
          patientName={original.name}
        />
      );
    }

    const unresolvedAlerts = alerts.filter((a) => !a.hasMarkedAsCompleted);

    if (unresolvedAlerts.length === 0) return <></>;

    const getReadableAlertText = (alertCode: AlertCode) => {
      switch (alertCode) {
        case "NoRegularAppointmentsInLastMonth":
          return t("case-load-manager-alert-no-regular-appointment");
        case "NoChatAppointmentsInLastMonth":
          return t("case-load-manager-alert-no-chat-appointment");
        case "ChatAppointmentCreatedOutsideBusinessHours":
          return t("case-load-manager-alert-chat-appointment-outside-hours");
        default:
          return t("case-load-manager-alert-unknown");
      }
    };

    return (
      <div>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger className="w-full flex items-center justify-center">
              <AlertCircle className="h-5 w-5 text-red-600" />
            </TooltipTrigger>
            <TooltipContent style={{ zIndex: 99999 }} className="max-w-[24rem]">
              <ul className="list-inside list-disc p-2">
                {unresolvedAlerts.map((a) => {
                  return <li key={a.code}>{getReadableAlertText(a.code)}</li>;
                })}
              </ul>

              <Button
                className="mt-3 text-sm w-full"
                onClick={async () => {
                  if (isResolvingAlerts) return;

                  await resolveAlerts({ caseLoadEntryId: original.id });
                }}
              >
                {isResolvingAlerts
                  ? t(
                      "case-load-manager-alert-resolving-single-alert-button-caption"
                    )
                  : t(
                      "case-load-manager-alert-resolve-single-alert-button-caption"
                    )}
              </Button>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  },
};
