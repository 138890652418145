import { SelectableEpdAppointmentType } from "../../types";

export type State = {
  preIntakeAppointmentTypes: SelectableEpdAppointmentType[];
  f2fIntakeAppointmentTypes: SelectableEpdAppointmentType[];
  regularConsultAppointmentTypes: SelectableEpdAppointmentType[];
  chatConsultAppointmentTypes: SelectableEpdAppointmentType[];
};

export type ActionType =
  | "updatePreIntake"
  | "updateF2fIntake"
  | "updateRegularConsult"
  | "updateChatConsult";

export type Action = {
  type: ActionType;
  payload: SelectableEpdAppointmentType[];
};

export const initialState: State = {
  preIntakeAppointmentTypes: [],
  f2fIntakeAppointmentTypes: [],
  regularConsultAppointmentTypes: [],
  chatConsultAppointmentTypes: [],
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "updatePreIntake":
      return { ...state, preIntakeAppointmentTypes: action.payload };
    case "updateF2fIntake":
      return { ...state, f2fIntakeAppointmentTypes: action.payload };
    case "updateRegularConsult":
      return { ...state, regularConsultAppointmentTypes: action.payload };
    case "updateChatConsult":
      return { ...state, chatConsultAppointmentTypes: action.payload };
    default:
      throw new Error();
  }
};
