import { ColumnDef } from "@tanstack/react-table";
import { Patient } from "../../../types";
import { Checkbox } from "../../../../../components/ui/checkbox";
import { useLocation } from "react-router-dom";
import { isDateInPastWeeks } from "../../../utils";

export const RowSelectorColName = "Select";

export const RowSelectorCol: ColumnDef<Patient> = {
  id: RowSelectorColName,
  enableResizing: false,
  size: 20,
  minSize: 20,
  maxSize: 20,
  header: ({ table }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const fromUrl = searchParams.get("period");
    const date = fromUrl ? new Date(fromUrl!) : new Date();

    if (isDateInPastWeeks(date)) return <></>;

    return (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    );
  },
  cell: ({ row }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const fromUrl = searchParams.get("period");
    const date = fromUrl ? new Date(fromUrl!) : new Date();

    return (
      <Checkbox
        disabled={isDateInPastWeeks(date)}
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    );
  },
};
