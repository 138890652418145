import { useReducer } from "react";
import { Skeleton } from "../../../../components/ui/skeleton";
import { useTranslation } from "react-i18next";
import {
  CurrentAppointmentPerCaseLoadAreaConfig,
  AppointmentConfigArea,
  SelectableEpdAppointmentType,
} from "../../types";
import { SaveButton } from "./saveButton";
import {
  useGetAllEpdAppointmentTypes,
  useGetSavedAppointmentTypeToCaseLoadAreaConfig,
} from "../hooks";
import { ActionType, initialState, reducer } from "./appointmentTypesState";
import { AppointmentTypeSelector } from "./appointmentTypeSelector";

export const AppointmentTypeSelectors = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchWith = (
    type: ActionType,
    payload: CurrentAppointmentPerCaseLoadAreaConfig[],
    area: AppointmentConfigArea
  ) => {
    dispatch({
      type: type,
      payload: payload
        .filter((appointmentType) => appointmentType.area === area)
        .map((x) => x.appointmentType),
    });
  };

  const {
    data: selectableAppointmentTypes,
    isFetching: areSelectableAppointmentTypesLoading,
  } = useGetAllEpdAppointmentTypes();

  const { data: originalValues, isFetching: isLoadingAppointmentTypes } =
    useGetSavedAppointmentTypeToCaseLoadAreaConfig((data) => {
      dispatchWith("updatePreIntake", data!, AppointmentConfigArea.PreIntake);
      dispatchWith("updateF2fIntake", data!, AppointmentConfigArea.F2fIntake);
      dispatchWith(
        "updateRegularConsult",
        data!,
        AppointmentConfigArea.RegularConsult
      );
      dispatchWith(
        "updateChatConsult",
        data!,
        AppointmentConfigArea.ChatConsult
      );
    });

  const { t } = useTranslation();

  const appointmentAreas: {
    name: string;
    selectedValues: SelectableEpdAppointmentType[];
    actionType: ActionType;
  }[] = [
    {
      name: t(
        "case-load-manager-admin-panel-patient-list-config-selector-preintake-caption"
      ),
      selectedValues: state.preIntakeAppointmentTypes,
      actionType: "updatePreIntake",
    },
    {
      name: t(
        "case-load-manager-admin-panel-patient-list-config-selector-f2fintake-caption"
      ),
      selectedValues: state.f2fIntakeAppointmentTypes,
      actionType: "updateF2fIntake",
    },
    {
      name: t(
        "case-load-manager-admin-panel-patient-list-config-selector-regularconsult-caption"
      ),
      selectedValues: state.regularConsultAppointmentTypes,
      actionType: "updateRegularConsult",
    },
    {
      name: t(
        "case-load-manager-admin-panel-patient-list-config-selector-chatconsult-caption"
      ),
      selectedValues: state.chatConsultAppointmentTypes,
      actionType: "updateChatConsult",
    },
  ];

  return (
    <div>
      <h2 className="text-3xl font-bold">
        {t("case-load-manager-admin-panel-patient-list-config-title")}
      </h2>
      <p>{t("case-load-manager-admin-panel-patient-list-config-desc")}</p>
      <div>
        {areSelectableAppointmentTypesLoading || isLoadingAppointmentTypes ? (
          <div className="mt-4">
            <div>
              <Skeleton className="w-full rounded mt-6 bg-muted/50 pb-2">
                <div className="px-2 pt-12 space-y-1">
                  <Skeleton className="h-14 w-full rounded" />
                  <Skeleton className="h-14 w-full rounded" />
                  <Skeleton className="h-14 w-full rounded" />
                  <Skeleton className="h-14 w-full rounded" />
                </div>
              </Skeleton>
            </div>
            <div className="w-full flex items-end justify-end mt-3">
              <Skeleton className="h-12 w-[9rem] rounded bg-muted/50" />
            </div>
          </div>
        ) : (
          <>
            <table className="border rounded mt-4 w-full">
              <tr className="border-b bg-background dark:bg-slate-900 text-muted-foreground">
                <th className="text-left p-4">
                  {t(
                    "case-load-manager-admin-panel-patient-list-config-appointment-type-area-col-caption"
                  )}
                </th>
                <th className="text-left p-4">
                  {t(
                    "case-load-manager-admin-panel-patient-list-config-appointment-type-col-caption"
                  )}{" "}
                </th>
              </tr>
              {appointmentAreas.map((a) => {
                return (
                  <tr key={a.name} className="border">
                    <td className="w-1/2 p-4">{a.name}</td>
                    <td className="w-1/2 pl-4 -pt-1 pb-1 pr-2">
                      <AppointmentTypeSelector
                        selectedValue={a.selectedValues}
                        selectableItems={selectableAppointmentTypes!}
                        onChange={(newValues) =>
                          dispatch({
                            type: a.actionType,
                            payload: newValues,
                          })
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
            <div className="mt-6">
              <SaveButton state={state} originalValues={originalValues ?? []} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
