import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Button } from "../../../../../components/ui/button";
import { Checkbox } from "../../../../../components/ui/checkbox";
import { useState } from "react";
import { useMassToggleSupportedPatients } from "../../hooks";
import { toast } from "sonner";
import { Patient } from "../../../types";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

export const HasChattedWithForMultiplePatientsDialogWrapper = ({
  isOpen,
  close,
  children,
  selectedPatients,
}: {
  isOpen: boolean;
  close: () => void;
  children: any;
  selectedPatients: Patient[];
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(false);

  const { mutate: save, isLoading: isSaving } = useMassToggleSupportedPatients(
    () => {
      setValue(false);
      close();

      toast(
        t(
          "case-load-manager-update-has-chatted-with-for-multiple-patients-confirmation-toast-title"
        ),
        {
          description: t(
            "case-load-manager-update-has-chatted-with-for-multiple-patients-confirmation-toast-desc"
          ),
        }
      );
    }
  );

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(newVal) => {
          if (!newVal) {
            setValue(false);
            close();
          }
        }}
      >
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>
              {t(
                "case-load-manager-update-has-chatted-with-for-multiple-patients-dialog-title"
              )}
            </DialogTitle>
            <DialogDescription>
              {t(
                "case-load-manager-update-has-chatted-with-for-multiple-patients-dialog-desc"
              )}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="chattedWith"
                checked={value}
                onCheckedChange={() => setValue(!value)}
                disabled={isSaving}
              />
              <label
                htmlFor="chattedWith"
                className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {t(
                  "case-load-manager-update-has-chatted-with-for-multiple-patients-checkbox-label"
                )}
              </label>
            </div>
            <div className="mt-2 pt-4 border-t text-xs">
              <p>{t("case-load-manager-chatted-with-confirm-dialog-desc")}</p>
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={async () => {
                if (!isSaving) {
                  await save({
                    caseLoadEntryIds: selectedPatients.map((x) => x.id),
                    value,
                  });
                }
              }}
              className="w-full"
            >
              {isSaving ? (
                <div className="flex items-center space-x-1">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>{t("case-load-manager-saving")}</p>
                </div>
              ) : (
                <p>{t("case-load-manager-save")}</p>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>

        {children}
      </Dialog>
    </>
  );
};
