import { ColumnDef } from "@tanstack/react-table";
import { Patient } from "../../../types";
import { useTranslation } from "react-i18next";
import { isPeriodInPastWeeks } from "../../../utils";
import { Textarea } from "../../../../../components/ui/textarea";
import { Button } from "../../../../../components/ui/button";
import { useState } from "react";
import { Loader2, Save, Undo } from "lucide-react";
import { useSaveSinglePsychologistComment } from "../../hooks";

export const YourCommentsColName = "Your Comments";

export const YourCommentsCol: ColumnDef<Patient> = {
  id: YourCommentsColName,
  accessorKey: "psychologistComment",
  size: 225,
  minSize: 100,
  header: () => {
    const { t } = useTranslation();
    return <p>{t("case-load-manager-column-your-comments")}</p>;
  },
  cell: ({ getValue, row }) => {
    const { t } = useTranslation();
    const defaultValue = getValue() as string | undefined;
    const [modifiedValue, setModifiedValue] = useState<string | undefined>(
      defaultValue
    );
    let hasChanged = (defaultValue ?? "") !== (modifiedValue ?? "");

    const isInPast = isPeriodInPastWeeks(row.original.period);

    const { mutate: save, isLoading: isSaving } =
      useSaveSinglePsychologistComment();

    if (isInPast) return <p>{defaultValue?.toString() ?? ""}</p>;

    return (
      <div>
        <Textarea
          value={modifiedValue ?? ""}
          className="text-xs"
          onChange={(e) => setModifiedValue(e.target.value)}
          disabled={isSaving}
          rows={4}
        />
        {hasChanged && (
          <div className="flex gap-2">
            <Button
              className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
              disabled={!hasChanged}
              onClick={async () =>
                await save({
                  caseLoadId: row.original.id,
                  comment: modifiedValue,
                })
              }
            >
              {isSaving ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>{t("case-load-manager-saving")}</p>
                </>
              ) : (
                <>
                  <Save className="w-4 h-4" />
                  <p>{t("case-load-manager-save")}</p>
                </>
              )}
            </Button>
            <Button
              variant="outline"
              className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
              disabled={!hasChanged}
              onClick={() => setModifiedValue(defaultValue)}
            >
              <Undo className="w-4 h-4" />
              <p>{t("case-load-manager-undo")}</p>
            </Button>
          </div>
        )}
      </div>
    );
  },
};
