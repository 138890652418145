import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Button } from "../../../../../components/ui/button";
import { Textarea } from "../../../../../components/ui/textarea";
import { useState } from "react";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { useSaveMultiplePsychologistComment } from "../../hooks";
import { Patient } from "../../../types";
import { useTranslation } from "react-i18next";

export const AddCommentsDialogWrapper = ({
  isOpen,
  close,
  selectedPatients,
  children,
}: {
  isOpen: boolean;
  close: () => void;
  selectedPatients: Patient[];
  children: any;
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");

  const { mutate: save, isLoading: isSaving } =
    useSaveMultiplePsychologistComment(() => {
      setComment("");
      close();

      toast(
        t(
          "case-load-manager-add-comments-for-multiple-patients-dialog-confirmation-toast-title"
        ),
        {
          description: t(
            "case-load-manager-add-comments-for-multiple-patients-dialog-confirmation-toast-desc"
          ),
        }
      );
    });

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(newVal) => {
          setComment("");

          if (!newVal) {
            close();
          }
        }}
      >
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>
              {t(
                "case-load-manager-add-comments-for-multiple-patients-dialog-title"
              )}
            </DialogTitle>
            <DialogDescription>
              {t(
                "case-load-manager-add-comments-for-multiple-patients-dialog-desc"
              )}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <Textarea
              value={comment ?? ""}
              onChange={(e) => setComment(e.target.value)}
              disabled={isSaving}
              rows={8}
            />
          </div>
          <DialogFooter>
            <Button
              onClick={async () => {
                if (!isSaving) {
                  await save({
                    comment,
                    caseLoadEntryIds: selectedPatients.map((p) => p.id),
                  });
                }
              }}
              className="w-full"
              disabled={comment === ""}
            >
              {isSaving ? (
                <div className="flex items-center space-x-1">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>
                    {t(
                      "case-load-manager-add-comments-for-multiple-patients-dialog-saving-comments-button"
                    )}
                  </p>
                </div>
              ) : (
                <p>
                  {t(
                    "case-load-manager-add-comments-for-multiple-patients-dialog-save-comments-button"
                  )}
                </p>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
        {children}
      </Dialog>
    </>
  );
};
