import { useEffect, useState } from "react";
import {
  getDefaultPageSize,
  getExcludeNoCheckinPatientsFromLocalStorage,
  getExcludeAlreadySupportedPatientsFromLocalStorage,
  getOnlyShowSoonOffboardingPatients,
  isDateInPastWeeks,
} from "../utils";
import { useGetAssignedPatients } from "./hooks";
import { CaseLoadTable } from "./caseLoadTable";
import { useDebounce } from "use-debounce";
import { SortingState } from "@tanstack/react-table";
import { getSortingData } from "./columns/sortingUtil";
import { CaseLoadAlert } from "../types";
import { SickOrHolidayLeave } from "../sickOrHolidayLeave";

export const CaseLoadOverview = ({
  date,
  onChangeDate,
  setAbsence,
  setAlerts,
  onlyCaseLoadsWithAlert,
  setOnlyCaseLoadsWithAlert,
}: {
  date: Date;
  onChangeDate: (newDate: Date) => void;
  setAbsence: (absence: { startDate: string; endDate: string } | null) => void;
  setAlerts: (
    alerts: {
      caseLoadEntryId: string;
      alerts: CaseLoadAlert[];
    }[]
  ) => void;
  onlyCaseLoadsWithAlert: boolean;
  setOnlyCaseLoadsWithAlert: (newVal: boolean) => void;
}) => {
  const [pageSize, setPageSize] = useState<number>(
    getDefaultPageSize("case-load-page-size")
  );
  const [skip, setSkip] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortingData = getSortingData(sorting);

  useEffect(() => {
    setSkip(0);
  }, [debouncedSearchTerm]);

  const updateSelectPageSize = (val: number) => {
    setPageSize(val);
    localStorage.setItem("case-load-page-size", val.toString());
  };

  const excludeNoCheckinNeededPatients =
    getExcludeNoCheckinPatientsFromLocalStorage();

  const excludeAlreadySupportedPatients =
    getExcludeAlreadySupportedPatientsFromLocalStorage();

  const showOnlySoonOffboardingPatients = getOnlyShowSoonOffboardingPatients();

  const {
    data: paginatedPatientData,
    isLoading,
    isFetching,
    isError,
  } = useGetAssignedPatients(
    date,
    pageSize,
    skip,
    onlyCaseLoadsWithAlert,
    excludeNoCheckinNeededPatients,
    excludeAlreadySupportedPatients,
    showOnlySoonOffboardingPatients,
    debouncedSearchTerm,
    sortingData.column,
    sortingData.isAsc,
    (x) => {
      setAbsence(x.metaData.absence);
      setAlerts(x.metaData.alerts);
    }
  );

  return (
    <div className="space-y-4">
      <CaseLoadTable
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        data={paginatedPatientData?.data ?? []}
        pagination={paginatedPatientData?.pagination}
        sorting={sorting}
        setSorting={setSorting}
        date={date}
        onChangeDate={onChangeDate}
        pageSize={pageSize}
        setPageSize={updateSelectPageSize}
        skip={skip}
        setSkip={setSkip}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isInPast={isDateInPastWeeks(date)}
        onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
        setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
      />

      <SickOrHolidayLeave />
    </div>
  );
};
