import { Button } from "../../../../components/ui/button";
import {
  AppointmentConfigArea,
  CurrentAppointmentPerCaseLoadAreaConfig,
} from "../../types";
import { toast } from "sonner";
import { useSaveAppointmentTypePerCaseLoadAreaConfig } from "./../hooks";
import { useTranslation } from "react-i18next";
import { State } from "./appointmentTypesState";
import { areArraysEqual } from "../../../../lib/utils";
import { Save } from "lucide-react";

export const SaveButton = ({
  originalValues,
  state,
}: {
  originalValues: CurrentAppointmentPerCaseLoadAreaConfig[];
  state: State;
}) => {
  const { t } = useTranslation();

  const { mutate: save, isLoading: isSaving } =
    useSaveAppointmentTypePerCaseLoadAreaConfig(() => {
      toast(
        t(
          "case-load-manager-admin-panel-patient-list-config-save-success-toast-title"
        ),
        {
          description: t(
            "case-load-manager-admin-panel-patient-list-config-save-success-toast-desc"
          ),
        }
      );
    });

  const compare = (area: AppointmentConfigArea) => {
    let fromState;

    switch (area) {
      case AppointmentConfigArea.PreIntake:
        fromState = state.preIntakeAppointmentTypes;
        break;
      case AppointmentConfigArea.F2fIntake:
        fromState = state.f2fIntakeAppointmentTypes;
        break;
      case AppointmentConfigArea.ChatConsult:
        fromState = state.chatConsultAppointmentTypes;
        break;
      case AppointmentConfigArea.RegularConsult:
        fromState = state.regularConsultAppointmentTypes;
        break;
    }

    return !areArraysEqual(
      fromState,
      originalValues
        ?.filter((x) => x.area === area)
        ?.map((x) => x.appointmentType) ?? [],
      "epdId"
    );
  };

  const isCurrentSelectionSameAsSaved = () => {
    return (
      compare(AppointmentConfigArea.PreIntake) ||
      compare(AppointmentConfigArea.F2fIntake) ||
      compare(AppointmentConfigArea.ChatConsult) ||
      compare(AppointmentConfigArea.RegularConsult)
    );
  };

  return (
    <div className="flex items-end justify-end col-span-2">
      <Button
        disabled={!isCurrentSelectionSameAsSaved()}
        className="px-12"
        onClick={async () => {
          await save({
            data: [
              {
                area: AppointmentConfigArea.PreIntake,
                appointmentTypeIds: state.preIntakeAppointmentTypes.map(
                  (x) => x.id
                ),
              },
              {
                area: AppointmentConfigArea.F2fIntake,
                appointmentTypeIds: state.f2fIntakeAppointmentTypes.map(
                  (x) => x.id
                ),
              },
              {
                area: AppointmentConfigArea.RegularConsult,
                appointmentTypeIds: state.regularConsultAppointmentTypes.map(
                  (x) => x.id
                ),
              },
              {
                area: AppointmentConfigArea.ChatConsult,
                appointmentTypeIds: state.chatConsultAppointmentTypes.map(
                  (x) => x.id
                ),
              },
            ],
          });
        }}
      >
        <div className="flex items-center space-x-2">
          <Save className="w-4 h-4" />
          <p>
            {isSaving
              ? t("case-load-manager-saving")
              : t("case-load-manager-save")}
          </p>
        </div>
      </Button>
    </div>
  );
};
