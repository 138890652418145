import { SearchTermInput } from "../../filters/searchTermInput";
import { isDateInPastWeeks } from "../../utils";
import { Patient } from "../../types";
import { Table } from "@tanstack/react-table";
import { AddPatientButton } from "../addPatient/addPatientButton";
import { Customize } from "../customization/customize";
import { MultiRowActions } from "../multiRowActions/multiRowActions";

export const TableHeader = ({
  table,
  pageSize,
  setPageSize,
  setSkip,
  date,
  onChangeDate,
  addNewPatientOpen,
  setAddNewPatientOpen,
  searchTerm,
  setSearchTerm,
  onlyCaseLoadsWithAlert,
  setOnlyCaseLoadsWithAlert,
}: {
  table: Table<Patient>;
  pageSize: number;
  setPageSize: (newSize: number) => void;
  setSkip: (newSkip: number) => void;
  date: Date;
  onChangeDate: (newDate: Date) => void;
  addNewPatientOpen: boolean;
  setAddNewPatientOpen: (newOpen: boolean) => void;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  onlyCaseLoadsWithAlert: boolean;
  setOnlyCaseLoadsWithAlert: (newVal: boolean) => void;
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="w-1/3">
        <SearchTermInput
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>

      <div className="flex gap-2">
        <Customize
          table={table}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setSkip={setSkip}
          date={date}
          onChangeDate={onChangeDate}
          onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
          setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
        />
        <MultiRowActions table={table} />
        <AddPatientButton
          open={addNewPatientOpen}
          onOpenChange={setAddNewPatientOpen}
          isInPast={isDateInPastWeeks(date)}
        />
      </div>
    </div>
  );
};
