import { Patient } from "../types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { useGetAutomaticBillingsForPatient } from "./hooks";
import { formatDateTime } from "./utils";
import { Loading } from "../tableStates/loading";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const PerPatientAutomaticBillingDialog = ({
  isOpen,
  setIsOpen,
  patient,
}: {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  patient: Patient;
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { t } = useTranslation();

  const getDefaultSelectedDate = () => {
    const fromUrl = searchParams.get("period");
    return fromUrl ? new Date(fromUrl!) : new Date();
  };
  const { data, isLoading } = useGetAutomaticBillingsForPatient(
    patient.patientId,
    getDefaultSelectedDate(),
    isOpen
  );

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(newValue) => {
        setIsOpen(newValue);
      }}
    >
      <DialogContent className="p-8 min-w-[1000px]">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            {t("automatic-billing-per-patient-dialog-title", {
              name: patient.name,
            })}
          </DialogTitle>
          <DialogDescription>
            {t("automatic-billing-per-patient-dialog-desc", {
              name: patient.name,
            })}
          </DialogDescription>
        </DialogHeader>
        <main>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>
                  {t("automatic-billing-column-appointment")}
                </TableHead>
                <TableHead>
                  {t("automatic-billing-column-generatedOn")}
                </TableHead>
                <TableHead>
                  {t("automatic-billing-column-generatedBy")}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <div className="w-full flex items-center justify-center space-x-1">
                      <Loading />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {data?.length == 0 ? (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <div className="w-full flex items-center justify-center space-x-1">
                          <p>{t("automatic-billing-no-bills-for-patient")}</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    data?.map((d) => {
                      return (
                        <TableRow key={d.id}>
                          <TableCell>{formatDateTime(d.appointment)}</TableCell>
                          <TableCell>{formatDateTime(d.generatedOn)}</TableCell>
                          <TableCell>{d.generatedBy}</TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </main>
      </DialogContent>
    </Dialog>
  );
};
