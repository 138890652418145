import { ColumnDef } from "@tanstack/react-table";
import { Patient } from "../../../types";
import { CheckCircle2, XCircle } from "lucide-react";
import { isPeriodInPastWeeks } from "../../../utils";
import { useTranslation } from "react-i18next";

export const IsPatientInsuredColName = "Insured";

export const IsPatientInsuredCol: ColumnDef<Patient> = {
  id: IsPatientInsuredColName,
  accessorKey: "isPatientInsured",
  size: 100,
  minSize: 80,
  header: () => {
    const { t } = useTranslation();

    return (
      <p className="text-center">{t("case-load-manager-column-is-insured")}</p>
    );
  },
  cell: ({ getValue, row }) => {
    const isInsured = getValue() == true;
    const isInPast = isPeriodInPastWeeks(row.original.period);

    return (
      <div className={`flex justify-center ${isInPast && "opacity-50"}`}>
        {isInsured ? (
          <CheckCircle2 className="h-5 w-5 text-green-600" />
        ) : (
          <XCircle className="h-5 w-5 text-red-600" />
        )}
      </div>
    );
  },
};
