import { ColumnDef } from "@tanstack/react-table";
import { Patient, PatientSupportType } from "../../../../types";
import { SortableCell } from "../../../../../../components/ui/table";
import { useEffect, useState } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../../components/ui/tooltip";
import { isGetAssignedPatientsFetching } from "../../../hooks";
import {
  isPeriodInPastWeeks,
  isPeriodInFutureWeeks,
  getSimpleFormattedDateTime,
} from "../../../../utils";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { ChatCheckBox } from "./checkboxes/chatCheckBox";
import { VideoCallCheckBox } from "./checkboxes/videoCallCheckBox";
import { useIsEnabled } from "../../../../../../feature-management/useIsEnabled";

export const HasChattedWithColName = "Status";

export const HasChattedWithCol: ColumnDef<Patient> = {
  id: HasChattedWithColName,
  accessorKey: "hasChattedWith",
  size: 220,
  minSize: 200,
  header: ({ column }) => {
    const { t } = useTranslation();

    return (
      <SortableCell
        column={column}
        label={t("case-load-manager-column-status")}
      />
    );
  },
  cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
    const { t } = useTranslation();
    const initialValue = getValue() as boolean;
    const [value, setValue] = useState(initialValue);
    const isInPast = isPeriodInPastWeeks(original.period);
    const isInFuture = isPeriodInFutureWeeks(original.period);

    const queryClient = useQueryClient();
    const isFetching = isGetAssignedPatientsFetching(queryClient);

    const onBlur = () => {
      // @ts-expect-error sssh
      table.options.meta?.updateData(index, id, value);
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <div
        className={`space-y-1 ${
          original.automaticBillingScheduledOn ? "mt-5" : "mt-0"
        }`}
      >
        <ChattedWithCell
          caseLoadId={original.id}
          supported={value}
          patientSupportType={original.patientSupportType}
          isFetching={isFetching}
          setAsHasChattedWithOn={original.setAsHasChattedWithOn}
          toggle={() => {
            setValue(!value);
          }}
          onBlur={onBlur}
          isInPast={isInPast}
          isInFuture={isInFuture}
          isCheckinNeeded={original.isCheckinNeeded}
          justificationIfCheckinNotNeeded={
            original.justificationIfCheckinNotNeeded
          }
          hasVideoCallConsultThisWeek={original.hasVideoCallConsultThisWeek}
          showLastVideoCallConsultWarning={
            original.numberOfVideoCallConsults >= 2
          }
        />
        {original.automaticBillingScheduledOn && (
          <div>
            <p
              className={`text-xs ${
                original.isAutomaticBillOutsideWorkingHours
                  ? "text-red-500"
                  : "text-gray-500"
              }`}
            >
              {t("case-load-manager-automatic-billing-scheduled-on")}{" "}
              {getSimpleFormattedDateTime(original.automaticBillingScheduledOn)}
            </p>
          </div>
        )}
      </div>
    );
  },
};

const ChattedWithCell = ({
  caseLoadId,
  isFetching,
  supported,
  patientSupportType,
  toggle,
  onBlur,
  isInPast,
  isInFuture,
  isCheckinNeeded,
  justificationIfCheckinNotNeeded,
  setAsHasChattedWithOn,
  hasVideoCallConsultThisWeek,
  showLastVideoCallConsultWarning,
}: {
  caseLoadId: string;
  isFetching: boolean;
  supported: boolean;
  patientSupportType: PatientSupportType;
  toggle: () => void;
  onBlur: () => void;
  isInPast: boolean;
  isInFuture: boolean;
  isCheckinNeeded: boolean;
  justificationIfCheckinNotNeeded: string | null;
  setAsHasChattedWithOn?: string;
  hasVideoCallConsultThisWeek: boolean;
  showLastVideoCallConsultWarning: boolean;
}) => {
  const { t } = useTranslation();

  const { data: isVideoConsultSupportEnabled } = useIsEnabled(
    "EnableVideoConsultSupportFromCaseloadManager"
  );

  var options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  const Wrapper = ({ children }: { children: JSX.Element }) => {
    if (supported && !isFetching) {
      return (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger>{children}</TooltipTrigger>
            <TooltipContent style={{ zIndex: 99999 }}>
              <p>
                {patientSupportType == "Chat"
                  ? t("case-load-manager-chatted-recorded-on")
                  : t("case-load-manager-videocall-recorded-on")}{" "}
                {new Date(setAsHasChattedWithOn!).toLocaleString(
                  "en-GB",
                  options
                )}
                .
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    } else {
      return <>{children}</>;
    }
  };

  const hasChatSupport =
    supported &&
    (patientSupportType === "Chat" || patientSupportType === "Both");

  const hasVideoCallSupport =
    supported &&
    (patientSupportType === "VideoCall" || patientSupportType === "Both");

  const showVideoCallCheckBox =
    isVideoConsultSupportEnabled && hasVideoCallConsultThisWeek;

  return (
    <Wrapper>
      <div className="space-y-4">
        <ChatCheckBox
          caseLoadId={caseLoadId}
          supported={hasChatSupport}
          toggle={toggle}
          onBlur={onBlur}
          isInPast={isInPast}
          isInFuture={isInFuture}
          isCheckinNeeded={isCheckinNeeded}
          justificationIfCheckinNotNeeded={justificationIfCheckinNotNeeded}
        />
        {showVideoCallCheckBox ? (
          <VideoCallCheckBox
            caseLoadId={caseLoadId}
            supported={hasVideoCallSupport}
            toggle={toggle}
            onBlur={onBlur}
            isInPast={isInPast}
            isInFuture={isInFuture}
            isCheckinNeeded={isCheckinNeeded}
            justificationIfCheckinNotNeeded={justificationIfCheckinNotNeeded}
            showLastVideoCallConsultWarning={showLastVideoCallConsultWarning}
          />
        ) : (
          <></>
        )}
      </div>
    </Wrapper>
  );
};
